/**
 * Styles for restaurant page
 */

.img-tags {
   height: 50px;
   margin-right: 10px;
}

.dish-image {
    max-height: 135px !important;
    width: 100% !important;
    object-fit: cover;
    cursor: pointer;
}

.margin-price {
    margin-top: 30px;
}

.cart-image {
    padding-top: 20px;
    margin-left: 10px;
}

.align-quantity-buttons {
    text-align: right;
}

.menu-item-left {
    margin-left: -15px;
}

@media (max-width: 990px) {
    .menu-item-left {
        margin-left: 0;
    }
}


@media (width: 768px) {
    .cart-image {
        margin-left: 5px;
    }
    .button-order {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .dish-price {
        float: right;
    }
    .dish-name {
        float: left;
    }
    .align-quantity-buttons {
        text-align: center;
    }
    .align-right {
        text-align: right;
    }
    .personalize {
        margin-left: 0px !important;
        text-align: center;
    }
    .category-addon {
        margin-left: 0px !important;
        text-align: center;
    }
}

@media screen and (max-width: 425px) {
    .description {
      display: none;
    }
    .img-tags {
        height: 30px;
        margin-right: 3px;
        margin-top: 10px;
     }
     .margin-price {
        margin-top: 10px;
    }
    .cart-image {
        padding-top: 5px;
        margin-left: 10px;
    }
    .personalize {
        margin-top: 15px;
        margin-left: 0px !important;
        text-align: center;
    }
  }

@media (min-width: 768px) {
    .display-inline {
        display: inline;
    }
    .floatright {
        float: right;
    }
}

@media (max-width: 320px) {
    .button-order {
        width: 100% !important;
    }
}

.addon-checkbox .form-check-input{
    display: none;
}
