h6.boldFirstLetter::first-letter{
    font-weight: bold;
}
@font-face {
    font-family: 'KasseBold';
    src: local('KasseBold'), url(./assets/font/KasseFLF-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Kasse';
    src: local('Kasse'), url(./assets/font/KasseFLF.ttf) format('truetype');
}

@font-face {
    font-family: 'MontserratLight';
    src: local('MontserratLight'), url(./assets/font/Montserrat-Light.otf) format('truetype');
}

@font-face {
    font-family: 'MontserratMedium';
    src: local('MontserratMedium'), url(./assets/font/Montserrat-Medium.otf) format('truetype');
}

@font-face {
    font-family: 'MontserratRegular';
    src: local('MontserratRegular'), url(./assets/font/Montserrat-Regular.otf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: local('Montserrat-Bold'), url(./assets/font/Montserrat-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: local('Montserrat-SemiBold'), url(./assets/font/Montserrat-SemiBold.otf) format('truetype');
}

#footer {
    font-family: 'MontserratRegular';
}

.pageContainer {
    margin-top: 10px;
}

.pageContainer .col {
    padding-top: 100px;
}

.card-img-overlay {
    padding-top: 10px;
}


.redPlaceholder {
    background-color: red;
    height: 10px;
}

.yellowPlaceholder {
    background-color: yellow;
    height: 10px;
}

.card .informationContainer {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.restaurant-header-img-container {
    padding-bottom: 170px;
}

.card .information {
    line-height: 1;
    padding-bottom: 5px;
    color: white;
    background-color: rgba(240 ,179 ,54 , 0.8);
    transition: .5s ease;
}

.card .information .address {
    padding-left: 5px;
    padding-right: 15px;
    padding-top: 10px;
    margin-top: -5px;
    font-size: 120%;
    line-height: 1.2;
}

.card .information .clock {
    display: inline-block;
    margin-left: 1px;
    vertical-align: middle;
    margin-top: 14px;
    width: 10%;
}

.card .information .workHours {
    display: inline-block;
    font-size: 15px;
    font-family: 'MontserratLight';
    vertical-align: middle;
    margin-top: 18px;
    width: 65%;
}

.card .information .restaurantType {
    display: inline-block;
    font-size: 15px;
    font-family: 'sansBold';
    vertical-align: middle;
    margin-top: -8px;
    width: 25%;
    font-weight: bold;
}

.hoursType {
    display: inline-block;
    width: 40%;
    font-family: 'MontserratLight';
}

.hours {
    display: inline-block;
    width: 55%;
    font-family: 'MontserratMedium';
}

.restaurantName {
    font-family: 'MontserratMedium';
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
    word-break: break-word;
    color: #272727;
}

.card-columns .card {
    margin-bottom: -0.25rem;

}

.card {
    border-radius: 0 !important;
    min-height: 250px !important;
    border: 0 !important;
}

.bottom-border {
    border-bottom: 1px solid #92c143;
    padding-bottom: 20px;
}

.grey-text {
    color: #444444;
    font-size: 24px;
    margin-top: 40px;
}

.grey-text2 {
    margin-top: -20px;
    color: #5e5e5e;
    font-size: 18px;
    margin-left: 15px;
}

.checkout-modal a:link {
    color: blue;
}

.checkout-modal a:active {
    color: red;
}

.whiteLabel div{
    color: white;
    font-family: 'MontserratLight';
}

.formFields {
    padding-top: 20px;
}

.hoursContainer {
    padding: 30px 25px;
    background-color: white;
    border: 2px solid #557627;
    margin: 1px 0;
    display: flex;
}

.discountButton {
    background-color: #EFBE39;
    color: white;
    font-family: 'MontserratMedium';
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
}

.simpleTotalField {
    background-color: white;
    color: #557627;
    font-weight: 500;
    padding: 2px 10px;
    margin-bottom: 5px;
    font-family: Montserrat-SemiBold;
    font-size: 24px;
}

.deliveryTotalField {
    font-family: MontserratRegular !important;
}

.checkout-contact-form input.form-control {
    padding: 0 5px !important;
    height: 30px !important;
}

.checkout-contact-form .input-container {
    padding: 0;
    border: 1px solid #92C143;
    margin-bottom: 15px;
}

.checkout-contact-form .input-container.invalid {
    border: 1px solid #EB0F2D;
}

.checkout-contact-form .input-label {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
}

.hoursLabel {
    width: 50%;
    color: #4F6D37;
    font-size: 24px;
    display: flex;
    align-items: center;
    font-family: MontserratMedium;
}

.totalField {
    background-color: white;
    color: #DF3D2F;
    font-weight: bold;
    padding: 2px 10px;
    font-family: Montserrat-Bold;
    font-size: 26px;
}

.payment-type-container {
    width: 50%;
}

.paymentButton,
.selectedPaymentButton {
    font-family: 'MontserratRegular';
    font-size: 20px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.paymentButton {
    border: 1px solid #5B5B5B;
    background-color: white;
    color: #5B5B5B;
}

.selectedPaymentButton {
    background-color: #5B5B5B;
    color: white;
    border: 1px solid #5B5B5B;
}

.checkout-dish {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding-left: 2px;
    font-size: 22px;
    font-family: 'Montserrat-SemiBold';
    color: #ffffff;
}

.checkout-addon {
    font-size: 16px;
    font-family: 'Montserrat-SemiBold';
    color: #ffffff;
}

.checkout-addon > div:first-child {
    padding-left: 10px;
}

.orangeButton {
    font-family: 'MontserratRegular';
    font-size: 24px !important;
    width: 100%;
    color: #414042 !important;
    background-color: #EFBE39 !important;
    border-radius: 0 !important;
    margin-top: 2px;
    text-align: center;
    min-height: 55px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border: 0 !important;
}

.orangeButton:hover {
    text-decoration: none;
    cursor: pointer;
}

.orangeButton:active,
.orangeButton:focus {
    outline: none !important;
    outline-offset: none !important;
    text-decoration: none !important;
    box-shadow: none !important;
}
.google-places-autocomplete__suggestion {
    z-index: 10;
}

.google-places-autocomplete__suggestions {
    z-index: 10;
    position: absolute;
    background-color: white;
    border: 1px solid lightgrey
}

.checkout-checkbox input {
    opacity: 0 !important;
}
.checkout-checkbox {
    padding-left: 6px !important;
    padding-top: 2px !important;
}

.privacyTitle {
    font-size: 59px;
}

.privacyBody {
      padding-left:150px;
      padding-right:150px;
}

.privacySection {
    margin-top:70px;
}

.privacyTitle {
    font-size: 59px;
}

.card-info-section {
    padding: 30px !important;
    border: 2px solid #557627;
    margin-top: 10px;
    color: #557627;
    font-family: 'MontserratMedium';
}

.custom-card-element {
    border: 1px solid #557627;
    padding: 8px;
    margin-top: 5px;
}

.checkout-contact-form .circle-icon {
    width: 15px !important;
    height: 15px !important;
    border-radius: 15px !important;
    font-size: 10px !important;
    margin-top: 3px !important;
}

.checkout-contact-form .form-control:disabled {
    background-color: #fff !important;
    cursor: not-allowed !important;
}

.circle-icon {
    margin-left: 5px;
    color: #557627;
    background-color: #EFBE39;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 20px;
    font-family: 'MontserratMedium';
    cursor: default;
    font-size: 14px;
}

.cart-dish-custom-tooltip .tooltip-inner,
.custom-tooltip .tooltip-inner {
    background-color: #EFBE39 !important;
    color: #232323 !important;
    border-radius: 50px;
}

.cart-dish-custom-tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.custom-tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.cart-dish-custom-tooltip.bs-tooltip-top .arrow::before,
.custom-tooltip.bs-tooltip-top .arrow::before {
    border-top-color: #EFBE39 !important;
}

.user-profile-input-label {
    font-family: 'MontserratLight';
    font-size: 20px;
    color: #565751;
}

.user-profile-input {
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 1px solid #6A833E !important;
    padding: 0 5px !important;
    font-size: 24px !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.user-profile-input.error {
    border-bottom: 1px solid red !important;
}

.user-profile-input-container {
    padding-bottom: 60px;
}

.user-profile-submit-btn {
    background-color: #92C143 !important;
    font-family: 'MontserratMedium' !important;
    min-height: 100px !important;
    width: 100% !important;
    color: white !important;
    font-size: 36px !important;
    box-shadow: none !important;
    border: 0 !important;
    border-radius: 0 !important;
    margin-bottom: 86px !important;
    margin-top: 50px !important;
}

.user-profile-reset-pass-bg {
    background-color: #EFECEA;
}

.link {
    font-family: Montserrat-SemiBold;
    color: #333333;
    text-decoration: underline;
}

.printable {
    display: none;
}

.small-text-box {
    padding-left: 61px;
    padding-right: 61px;
}

@media print {

    #root {
        display: none;
    }

    .noPrint {
        display: none;
    }

    .printable {
        display: block;
    }

}

.modalLoginButton {
    font-family: MontserratMedium;
    background-color: #92C143;
    color: #fff;
    width: 100%;
    cursor: pointer;
    padding: 12px;
    margin-bottom: 30px;
    font-size: 28px;
}

.modalRegisterButton {
    font-family: MontserratRegular;
    background-color: #F7E0A9;
    color: #557627;
    width: 100%;
    cursor: pointer;
    padding: 12px;
    margin-bottom: 30px;
    font-size: 28px;
}

.modalGuestButton {
    font-family: MontserratMedium;
    border: 1px solid #92C143;
    background-color: #fff;
    color: #547200;
    width: 100%;
    cursor: pointer;
    padding: 12px;
    margin-bottom: 30px;
    font-size: 24px;
}

.modalGuestButton a {
    color: #547200 !important;
}

.not-reg-yet-subtitle {
    text-align: center;
    font-family: MontserratRegular;
    color: #000000;
    font-size: 24px;
    padding-bottom: 15px;
    padding-left: 90px;
    padding-right: 90px;
}

.sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

.react-confirm-alert-overlay {
    background-color: rgba(0,0,0,0.5) !important;
}

.react-confirm-alert {
    background-color: #fff !important;
    padding: 30px !important;
    border-radius: 5px !important;
}

@media screen and (max-width: 375px) {
    .checkout-dish {
        font-size: 12px !important;
    }

    .checkout-addon {
        font-size: 10px !important;
    }

    .checkout-addon > div:first-child {
        padding-left: 5px;
    }

    .hoursLabel {
        font-size: 20px;
    }

    .hoursType,
    .hours {
        width: 50%;
    }

    .card .information .workHours {
        width: 85%;
    }
}

@media (max-width : 425px) {
    .privacyTitle {
        font-size: 20px !important;
    }

    .privacySection {
        margin-top:10px !important;
    }

    .checkout-dish {
        font-size: 18px;
    }

    .checkout-addon {
        font-size: 12px;
    }

    .simpleTotalField {
        font-size: 16px;
    }

    .totalField {
        font-size: 18px;
    }
}

.underlinedElement:hover {
    text-decoration: underline;
}

.modal-content {
    border-radius: 0px!important;
}
@media (min-width: 550px){
    h4 {
        font-size: 1.8rem;
    }

    .card {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        height: auto;
        min-height: 200px;
        background-size: cover;
        cursor: pointer;
    }
}

@media only screen and (max-width : 575px){

    .card-group {
        column-count: 1;
    }

    .card {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        height: auto;
        background-size: cover;
        cursor: pointer;
    }

    .payment-type-container {
        width: 100%;
    }
}

@media only screen and (min-width : 576px){

    .card-group {
        column-count: 1;
    }
    .card {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        height: auto;
        background-size: cover;
        cursor: pointer;
    }
}

@media only screen and (max-width : 768px){

    .privacyBody {
        padding-left:0 !important;
        padding-right:0 !important;
    }

    .privacyTitle {
        font-size: 55px;
    }

    .footerElement {
        text-align: left;
    }

    .not-reg-yet-subtitle {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (min-width : 768px){

    .card-group {
        column-count: 1;
    }

    .card {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        height: auto;
        background-size: cover;
        cursor: pointer;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .checkout-dish {
        font-size: 16px;
    }
    .checkout-addon {
        font-size: 12px;
    }
    .simpleTotalField {
        font-size: 22px;
    }
    .totalField {
        font-size: 24px;
    }
}

@media (max-width: 990px) {
    .restaurant-info {
        text-align: center;
    }

    .cart-dish-custom-tooltip {
        display: none !important;
    }
}

@media only screen and (min-width : 992px) {

    .card-group {
        column-count: 2;
    }

    .card {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        height: auto;
        background-size: cover;
        cursor: pointer;
    }

    .restaurantListCol {
        padding-left: 0px;
    }

    .privacyColumn {
        padding-left: 0px;
    }

    .supported {
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        border-left: 2px dashed white;
        display: flex;
    }
}

@media only screen and (max-width : 1024px){
    h6 {
        font-size: 0.7rem;
    }

    .card .information .clock,
    .card .information .workHours {
        margin-top: 0;
    }

    .card {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        height: auto;
        background-size: cover;
        cursor: pointer;
    }

    .restaurant-header-img-container {
        padding-bottom: 100px !important;
    }

    .tagsMargin {
        margin-bottom: 25px;
    }

    .small-text-box {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media only screen and (min-width : 1025px){
    h6.other-column {
        font-size: 1rem;
    }
    h6.restaurant-info {
        font-size: 1.2rem;
    }
    h6 {
        font-size: 0.9rem;
    }

    .card {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        height: auto;
        background-size: cover;
        cursor: pointer;
    }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .discountButton {
        font-size: 14px !important;
    }
}

@media only screen and (min-width : 1200px){

    .card-columns {
        column-count: 2;
    }

    .card {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        height: auto;
        background-size: cover;
        cursor: pointer;
    }
}
