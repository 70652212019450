.orderLabel {
    border-radius: 0 !important;
    color:#707168;
    border-color: #bbd88a !important;
    font-family: "MontserratRegular";
    font-weight: bold;
}

.orderLabel:disabled {
    background-color: white !important;
}