/**
 * Styles for cart block
 */

.pick-up-time-container {
  text-align: center;
  width: 50%;
  display: inline-block;
  border: 1px solid white;
  background-color:  #92C143;
  border-radius: 1px;
}

.pick-up-time-select {
  background-color: #92C143;
  color: white;
  min-height: 28px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: MontserratLight;
}

.pick-up-time-select:focus {
  outline: none;
}

.pick-up-closed {
  border-top: 1px solid;
  border-color: white;
  padding: 10px;
  color: #493a10;
  font-size: 16px;
  vertical-align: middle;
  background-color: #EFBE39;
  font-family: MontserratMedium;
  text-align: center;
  min-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.delivery-availability {
  border: 3px solid #5C7433;
  padding: 10px;
  color: #5C7433;
  font-size: 100%;
  vertical-align: middle;
  background-color: #e3e3e3;
  font-family: MontserratMedium;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delivery-availability-no-street {
  border: 3px solid red;
  padding: 10px;
  color: red;
  font-size: 100%;
  vertical-align: middle;
  background-color: #e3e3e3;
  font-family: MontserratMedium;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delivery-pick-up-btn {
  width: 45%;
  font-size: 15px !important;
  font-weight: bold !important;
  text-align: center !important;
  border-radius: 1px !important;
  border: none !important;
  outline: none !important;
}

.delivery-pick-up-btn:focus {
  outline:none !important;
  box-shadow: none !important;
}

.address-search-closed {
  position: absolute;
  right: 15px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: left .5s;
}

.address-search-opened {
  position: absolute;
  right: 15px;
  left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: left .5s;
}

.autocomplete-field-opened {
  width: 100%;
}

.search-location-input input {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.search-bar .search-location-input input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.autocomplete-button-field-opened {
  width: 40px;
}

.autocomplete-field-closed {
  width: 100%;
}

.autocomplete-button-field-closed {
  width: 22px;
}

.delivery-pick-up-btn:hover {
  text-decoration: none !important;
  border: none !important;
  outline: none !important;
}

.whiteBoldedNumber {
  color: white;
  font-family: Montserrat-SemiBold;
  font-size: 28px;
  letter-spacing: 5px;
  margin-right: -5px;
}

.autocomplete-field-closed input::-webkit-input-placeholder { /* WebKit browsers */
  color:    #fff;
}
.autocomplete-field-closed input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #fff;
}
.autocomplete-field-closed input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #fff;
}
.autocomplete-field-closed input:-ms-input-placeholder { /* Internet Explorer 10+ */
  color:    #fff;
}

.autocomplete-field-opened .pac-target-input::-webkit-input-placeholder { /* WebKit browsers */
  color:    #fff;
}
.autocomplete-field-opened .pac-target-input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #fff;
}
.autocomplete-field-opened .pac-target-input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #fff;
}
.autocomplete-field-opened .pac-target-input:-ms-input-placeholder { /* Internet Explorer 10+ */
  color:    #fff;
}

@media(width : 1024px) {
  .delivery-pick-up-btn {
    width: 45%;
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: center !important;
    border-radius: 1px !important;
    border: none !important;
    outline: none !important;
  }
}

.pick-up-time-select:disabled {
  opacity: 1 !important;
}

.submitPrice {
  font-size: larger;
}

.vatText {
  font-size: medium;
}

@media(max-width: 425px) {
  .submitPrice {
    font-size: 14px !important;
  }
  .vatText {
    font-size: 11px !important;
  }
}

@media(max-width: 990px) {
  .submitPrice {
    font-size: 16px !important;
  }
  .vatText {
    font-size: 11px !important;
  }
}

@media(min-width: 768px) {
  .submitLeftColumn {
    padding-right:40px !important;
  }

  .submitRightColumn {
    padding-left:40px !important;
  }
}
