@font-face {
    font-family: 'MontserratLight';
    src: local('MontserratLight'), url(../assets/font/Montserrat-Light.otf) format('truetype');
}

@font-face {
    font-family: 'MontserratMedium';
    src: local('MontserratMedium'), url(../assets/font/Montserrat-Medium.otf) format('truetype');
}

@font-face {
    font-family: 'MontserratRegular';
    src: local('MontserratRegular'), url(../assets/font/Montserrat-Regular.otf) format('truetype');
}

html {
    scroll-behavior: smooth;
}

.search-bar {
    background-color: #D1D2D3 !important;
    border: 1px solid black;
}

.logo {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 8.5rem;
}

.header-white-bg {
    background-color: white;
    z-index: 1;
}

.scroll-margin {
    scroll-margin-top: 154px;
}

.philosophy {
    background-image: url(../assets/image/pizza.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 150px;
    margin-top: 50px;
}

.card-color {
    background-color: #92c1437F !important;
}

.menu-container {
    background-color: white;
    z-index: 2;
    position: fixed;
    width: 100%;
}

.menu-container {
    min-width: 320px;
}

.menuSection {
    right: 0px;
    bottom: 0px;
    position: absolute;
    z-index: 1;
}

.menu.top {
    margin-left: auto;
    text-align: right;
}

.no-rest-found-msg {
    color: white;
    font-size: 27px;
    font-family: 'MontserratLight'
}

@media (min-width: 1041px) {
    .menu.top .item {
        vertical-align: middle;
    }

}

.menu.top .item {
    color: #92c143;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
}

.menuIcon {
    display: none;
}

.menu-bottom {
    color: white;
    font-size: 18px;
    border-radius: 3px;
}

.drop-down-item {
    background-color: #92c143;
    color:white;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.drop-down-itemLoginRegister {
    background-color: #E27C2A;
    color:white;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

#search-restaurant-by-name::placeholder {
    color: rgb(106, 131, 33);
}

@media (min-width: 991px) {
    .search-by-name-row {
        padding-right: 0 !important;
        margin: 50px 0 35px 0;
    }
}

@media (max-width: 991px) {
    .search-by-name-row {
        padding-right: 15px;
        margin: 15px 0 35px 0;
        text-align: center;
    }
    .search-by-name-btn {
        max-width: 160px;
    }
}

@media(max-width: 768px) {
    .menu-bottom {
        display: none;
    }

    .menu.top .itemLoginRegister {
        display: none !important;
    }

    .menuIcon {
        display: inline;
    }
    .logo{
        margin-left: 0px;
    }
    .dash-border {
        padding-top: 50px;
        margin-left: 15px;
        margin-top: 20px;
    }
    .menuSection{
        right: -30px;
        z-index: 1;
    }
    .terms {
        padding-left: 15px !important;
    }
    .privacy {
        padding-left: 15px !important;
    }
    .supported {
        display: none;
    }
}


.menu.top .itemLoginRegister {
    color: #EDBE40;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    cursor: pointer
}

.drop-down-menu {
    text-align: center;
    z-index: 1;
    position: absolute;
    left: 0px;
    right: 0px;
    display: none;

}
.user-modal {
    font-family: 'MontserratMedium';
    line-Height: 2.4;
    width: 300px;
    padding-Left: 25px;
    padding-Top: 10px;
    padding-Bottom: 20px;
    margin-Top: -20px;
    margin-right: 125px;
    position: absolute;
    right: 0;
    border: 1px solid #EDBE40;
    background-Color: white;
    color: black;
    text-Align: left;
    transition: all 2s linear;
}

.user-modal-hidden {
    display: none;
}

.popup-item {
    cursor: pointer;
}
.popup-item:hover {
    text-decoration: underline;
}

.header-row {
    align-items: flex-end;
}

@media(min-width: 768px) {
    .drop-down-menu{
        top: 80px;
    }
}

@media(min-width: 769px) {
    .supported-resp {
        display: none;
    }
}

@media(max-width: 768px) and (min-width: 425px) {
    .logo {
        height: 55px;
    }
}

@media (max-width: 425px) {

    .header-size {
        height: 80px;
    }
    .logo {
        height: 35px;
        padding-top: 0px;
        padding-bottom: 0px;

    }
    .menu.top .item {
        color: #92c143;
        display: inline-block;
        margin-left: 10px;
        margin-right: 0px;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1);
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1);
        transition: all 200ms ease-in;
        transform: scale(1);
    }
    .divider-height {
        height: 75px !important;
    }
    .padding-footer {
        padding-left: 40px;
    }
    .menu-padding {
        padding-bottom: 40px;
    }
}

@media (max-width: 376px) {

    .header-row {
        align-items: center;
    }

}

.animation {
    scroll-behavior: smooth;
}

.header-fixed {
    background-color: white;
    z-index: 2;

    width: 100%;
}



.dash-border {
    border-left: 2px dashed white;
}

.divider-height {
    height: 124px;
}

.search-by-name-btn {
    font-size: 30px !important;
    font-family: MontserratMedium;
    font-weight: bold !important;
    border-radius: 0 !important;
    background-color: #92c143 !important;
    color: black !important;
    border: 1px solid black !important;
    width: 100%;
    height: 100%;
}

#formBasicCheckbox {
    width: 17px;
    height: 20px;
}

#formBasicCheckboxTwo {
    width: 17px;
    height: 20px;
}

#formBasicCheckboxThree {
    width: 17px;
    height: 20px;
}

#formBasicCheckboxFour {
    width: 17px;
    height: 20px;
}

@media(width: 1024px) {
    .vvimage {
        margin-top: 10px;
    }
    .terms {
        margin-left: 10px;
    }
}

.menu-item {
    display: inline;
    margin-left: 1rem;
    cursor: pointer;
    font-family: MontserratLight;
    font-weight: bold;
}

.menu-item-en {
    display: inline;
    margin-left: 1rem;
    cursor: pointer;
    font-family: MontserratLight;
    font-weight: bold;
}

@media (min-width: 1317px) {
    .menu-item {
        margin-left: 4rem;
    }
    .menu-item-en {
        margin-left: 2rem;
    }
}

@media (min-width: 1800px) {
    .menu-item {
        margin-left: 90px;
    }
}

@media (max-width: 575px) {
    .dash-border {
        margin-left: 0 !important;
    }
}
