@media (min-width: 550px){
  h4 {
      font-size: 3.0rem;
  }
}

.login-form {
  border: 2px solid;
  border-color: white;
}

.loginInput:focus {
  outline: none !important;
  outline-offset: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
}

.loginButton:hover, .loginButton:active, .loginButton:focus {
  outline: none !important;
  outline-offset: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
}

.formPostal {
  display:inline-block;
  width: 45%;
}

@media (max-width: 425px){
  .formPostal {
    display:inline-block;
    width: 100%;
  }
}